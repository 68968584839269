import React, {
  useCallback,
  useMemo,
  useState,
  CSSProperties,
  createContext,
  useContext,
} from 'react';
import {
  Autocomplete,
  Checkbox,
  FormControlLabel,
  IconButton,
  TextField,
  Paper,
  Box,
} from '@mui/material';
import ClearIcon from '@mui/icons-material/ClearOutlined';
import ArrowUpwardIcon from '@mui/icons-material/ArrowUpward';
import ArrowDownwardIcon from '@mui/icons-material/ArrowDownward';
import ArrowRightIcon from '@mui/icons-material/ArrowForward';
import ArrowLeftIcon from '@mui/icons-material/ArrowBack';
import AlignHorizontalCenterIcon from '@mui/icons-material/AlignHorizontalCenter';
import AlignVerticalCenterIcon from '@mui/icons-material/AlignVerticalCenter';
import ZoomInIcon from '@mui/icons-material/ZoomIn';
import ZoomOutIcon from '@mui/icons-material/ZoomOut';
import AddIcon from '@mui/icons-material/Add';
import DeleteIcon from '@mui/icons-material/Delete';

export type Renderable = {
  render: () => JSX.Element;
};

export const StorageContext = createContext<string>('');

// Hook
function useLocalStorage<T>(
  key: string,
  initialValue: T,
): [T, (value: T) => void] {
  const storageContext = useContext(StorageContext);

  const decode = useMemo(
    () =>
      (value: string): T => {
        if (value === 'undefined') {
          return undefined as unknown as T;
        }

        return JSON.parse(value) as T;
      },
    [],
  );

  const fullKey = useMemo(
    () => `${storageContext}-${key}`,
    [storageContext, key],
  );

  // State to store our value
  // Pass initial state function to useState so logic is only executed once
  const [storedValue, setStoredValue] = useState<T>(() => {
    const item = window.localStorage.getItem(fullKey);
    return item ? decode(item) : initialValue;
  });

  // Return a wrapped version of useState's setter function that ...
  // ... persists the new value to localStorage.
  const setValue = useMemo(
    () => (value: T) => {
      const encodedValue = JSON.stringify(value);
      const encodedInitialValue = JSON.stringify(initialValue);
      if (encodedValue !== encodedInitialValue) {
        window.localStorage.setItem(fullKey, JSON.stringify(value));
      } else {
        window.localStorage.removeItem(fullKey);
      }
      setStoredValue(value);
    },
    [fullKey, initialValue],
  );

  return [storedValue, setValue];
}

export function useEditableData<T>(desc: string, original: T | string) {
  const [data, setData] = useLocalStorage(desc, original);
  const reset = useCallback(() => {
    setData(original);
  }, [original, setData]);

  return {
    data,
    render: () => (
      <TextField
        size="small"
        multiline
        fullWidth
        margin="normal"
        label={desc}
        value={data}
        onChange={event => {
          setData(event.target.value);
        }}
        InputProps={{
          endAdornment: (
            <IconButton onClick={reset}>
              <ClearIcon />
            </IconButton>
          ),
        }}
      />
    ),
  };
}

export const useEditableBoolean = (desc: string, original: boolean) => {
  const [data, setData] = useLocalStorage(desc, original);

  return {
    data,
    render: () => (
      <FormControlLabel
        control={
          <Checkbox
            checked={data}
            onChange={(_, checked) => setData(checked)}
          />
        }
        label={desc}
      />
    ),
  };
};

export const useMultiChoiceData = (
  desc: string,
  originals: string[],
  images = false,
) => {
  const options = useMemo(
    () => originals.map(original => ({ id: original, label: original })),
    [originals],
  );

  const [data, setData] = useLocalStorage<
    string | { id: string; label: string } | null
  >(desc, originals[0] ?? null);

  const stringData = typeof data === 'string' ? data : data?.id ?? '';

  return {
    data: stringData,
    render: () => (
      <div style={{ width: '100%', margin: '8px 0 16px 0' }}>
        <Autocomplete
          size="small"
          disablePortal
          fullWidth
          value={data}
          freeSolo
          onChange={(_, newValue) => {
            setData(newValue);
          }}
          options={options}
          renderInput={params => <TextField {...params} label={desc} />}
          renderOption={
            images
              ? (props, option) => (
                  <Box
                    component="li"
                    sx={{ '& > img': { mr: 2, flexShrink: 0 } }}
                    {...props}
                  >
                    <img loading="lazy" width="80" src={option.label} alt="" />
                    {option.label}
                  </Box>
                )
              : undefined
          }
        />
      </div>
    ),
  };
};

export const useEditableImagePosition = (
  desc: string,
  objectFit: CSSProperties['objectFit'] = 'cover',
): Renderable & {
  data: {
    style: CSSProperties;
    onPointerDown: React.PointerEventHandler<HTMLDivElement>;
    onPointerUp: React.PointerEventHandler<HTMLDivElement>;
    onPointerMove: React.PointerEventHandler<HTMLDivElement>;
    // onMouseWheel: React.MouseEventHandler<HTMLDivElement>;
  };
} => {
  const [isDragging, setIsDragging] = useState(false);
  const [scale, setScale] = useLocalStorage(`${desc}-scale`, 1.1);
  const [{ x, y }, setXY] = useLocalStorage(`${desc}-xy`, { x: 0, y: 0 });
  const [offset, setOffsetXY] = useState<
    | {
        x: number;
        y: number;
      }
    | undefined
  >(undefined);

  const pointerMoved = useCallback<React.PointerEventHandler<HTMLDivElement>>(
    e => {
      if (!offset) {
        return;
      }

      setXY({ x: e.clientX - offset.x, y: e.clientY - offset.y });
    },
    [offset, setXY],
  );

  const style = useMemo<CSSProperties>(
    () => ({
      objectFit,
      objectPosition: `calc(50% + ${x}px) calc(50% + ${y}px)`,
      transform: `scale(${scale})`,
      // High visibility background when dragging
      ...(isDragging
        ? {
            backgroundColor: 'red',
          }
        : {}),
    }),
    [isDragging, objectFit, scale, x, y],
  );

  return {
    data: {
      style,
      onPointerDown: e => {
        (e.target as HTMLDivElement).setPointerCapture(e.pointerId);
        setIsDragging(true);
        setOffsetXY({ x: e.clientX - x, y: e.clientY - y });
        e.preventDefault();
      },
      onPointerUp: e => {
        (e.target as HTMLDivElement).releasePointerCapture(e.pointerId);
        setIsDragging(false);
        setOffsetXY(undefined);
        e.preventDefault();
      },
      onPointerMove: pointerMoved,
    },
    render: () => (
      <div
        style={{
          width: '100%',
          margin: '8px 0 16px 0',
          display: 'flex',
          flexDirection: 'column',
        }}
      >
        {desc}
        <div
          style={{
            width: '100%',
            display: 'flex',
            flexDirection: 'row',
          }}
        >
          <IconButton
            onClick={() => {
              setXY({ x: x - 1, y });
            }}
          >
            <ArrowLeftIcon />
          </IconButton>
          <IconButton
            onClick={() => {
              setXY({ x: x + 1, y });
            }}
          >
            <ArrowRightIcon />
          </IconButton>
          <IconButton
            onClick={() => {
              setXY({ x, y: y - 1 });
            }}
          >
            <ArrowUpwardIcon />
          </IconButton>
          <IconButton
            onClick={() => {
              setXY({ x, y: y + 1 });
            }}
          >
            <ArrowDownwardIcon />
          </IconButton>
          <IconButton
            onClick={() => {
              setXY({ x, y: 0 });
            }}
          >
            <AlignVerticalCenterIcon />
          </IconButton>
          <IconButton
            onClick={() => {
              setXY({ x: 0, y });
            }}
          >
            <AlignHorizontalCenterIcon />
          </IconButton>
          <IconButton
            onClick={() => {
              setScale(scale / 0.95);
            }}
          >
            <ZoomInIcon />
          </IconButton>
          <IconButton
            onClick={() => {
              setScale(scale * 0.95);
            }}
          >
            <ZoomOutIcon />
          </IconButton>
          <IconButton
            onClick={() => {
              setXY({ x: 0, y: 0 });
              setScale(1.1);
            }}
          >
            <ClearIcon />
          </IconButton>
        </div>
      </div>
    ),
  };
};

export const useEditableArray = <T extends Record<string, string>>(
  desc: string,
  template: T,
  originals: T[],
) => {
  const [datas, setDatas] = useLocalStorage(desc, originals);
  const setIndex = useCallback(
    (data, index) => {
      setDatas(Object.assign([], datas, { [index]: data }));
    },
    [datas, setDatas],
  );

  const resetIndex = useCallback(
    index => {
      setDatas(Object.assign([], datas, { [index]: originals[index] }));
    },
    [datas, originals, setDatas],
  );

  const swapIndex = (array: T[], from: number, to: number) => {
    const item = array[from];
    if (item === undefined) {
      return array;
    }

    return from < to
      ? [
          ...array.slice(0, from),
          ...array.slice(from + 1, to + 1),
          item,
          ...array.slice(to + 1),
        ]
      : [
          ...array.slice(0, to),
          item,
          ...array.slice(to, from),
          ...array.slice(from + 1),
        ];
  };

  const removeIndex = (array: T[], idx: number) => [
    ...array.slice(0, idx),
    ...array.slice(idx + 1),
  ];

  return {
    data: datas,
    render: () => (
      <Paper style={{ width: '100%', margin: '8px 0 16px 0', padding: '16px' }}>
        {desc}

        {datas.map((data, idx) => (
          // eslint-disable-next-line react/no-array-index-key
          <div key={idx} style={{ margin: '0 0 32px 0' }}>
            {Object.keys(template).map(key => (
              <TextField
                size="small"
                key={key}
                multiline
                fullWidth
                margin="normal"
                label={`Rad ${idx + 1} - ${key}`}
                value={data[key]}
                onChange={event => {
                  setIndex({ ...data, [key]: event.target.value }, idx);
                }}
                InputProps={{
                  endAdornment: (
                    <IconButton onClick={() => resetIndex(idx)}>
                      <ClearIcon />
                    </IconButton>
                  ),
                }}
              />
            ))}
            <IconButton
              onClick={() => {
                setDatas(swapIndex(datas, idx, idx - 1));
              }}
            >
              <ArrowUpwardIcon />
            </IconButton>
            <IconButton
              onClick={() => {
                setDatas(swapIndex(datas, idx, idx + 1));
              }}
            >
              <ArrowDownwardIcon />
            </IconButton>
            <IconButton
              onClick={() => {
                setDatas(removeIndex(datas, idx));
              }}
            >
              <DeleteIcon />
            </IconButton>
          </div>
        ))}
        <IconButton
          onClick={() => {
            setDatas([...datas, template]);
          }}
        >
          <AddIcon />
        </IconButton>
        <IconButton
          onClick={() => {
            setDatas(originals);
          }}
        >
          <ClearIcon />
        </IconButton>
      </Paper>
    ),
  };
};

export type ImageModifier = ReturnType<typeof useEditableImagePosition>['data'];
